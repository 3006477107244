/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from 'axios';
import { retry } from '../../utils/promise-helper';
import logErrorToElastic from '../logs/logErrorToElastic';

// export const baseURL = 'https://autocenter.mestresdaweb.io/';
export const baseURL = 'https://api.autocenterapp.com/';
// export const baseURL = 'http://localhost:3000/';
// export const baseURL = 'http://164.92.101.211:3000/';
// export const baseURL = 'https://testserver.autocenterapp.com/';

const api = axios.create({
  baseURL,
  timeout: 60 * 1000 * 2,
});

api.interceptors.response.use(
  response => response,
  async error => {
    const { config } = error;
    if (!config || config.__isRetryRequest) {
      return Promise.reject(error);
    }

    config.__isRetryRequest = true;

    if (error.response) {
      const errorData = {
        endpoint: config.url,
        method: config.method,
        status: error.response.status,
        statusText: error.response.statusText,
        data: error.response.data,
        headers: config.headers,
      };

      await logErrorToElastic('axios', errorData);
    }

    return retry(1, 5000, () => api(config));
  },
);

api.interceptors.request.use(
  async config => {
    const accessToken = localStorage.getItem('@AutoCenter: accessToken');
    if (accessToken) {
      config.headers!.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => Promise.reject(error),
);
export default api;
